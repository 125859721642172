<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Kode Outlet</label>
                <InputText id="cust_code" v-model="forms.cust_code" required="true" autofocus :class="{ 'p-invalid': errorAdd.cust_code }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.cust_code" >{{ errorAdd.cust_code[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Nama Outlet</label>
                <InputText id="cust_name" v-model="forms.cust_name" required="true" autofocus :class="{ 'p-invalid': errorAdd.cust_name }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.cust_name" >{{ errorAdd.cust_name[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Klasifikasi Outlet</label>
                    <Dropdown id="klasifikasi_id" dataKey="klasifikasi_id" v-model="forms.klasifikasi_id" :loading="loadingDropdownKlasifikasi"
                        :options="dataDropdownKlasifikasi" :class="{ 'p-invalid': errorAdd.klasifikasi_id }"
                        optionLabel="label" optionValue="klasifikasi_id" placeholder="Pilih Klasifikasi"
                        :filter="true" :showClear="true" @filter="searchDropdownKlasifikasiOutlet($event, 'add')"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.klasifikasi_id">{{
                        errorAdd.klasifikasi_id[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Region</label>
                    <Dropdown id="region" dataKey="region_id" v-model="forms.region_id" :loading="loadingDropdownRegion"
                        :options="dataDropdownRegion" :class="{ 'p-invalid': errorAdd.region_id }"
                        optionLabel="region_name" optionValue="region_id" placeholder="Pilih Region"
                        :filter="true" :showClear="true" @filter="searchDropdownRegion($event, 'add')"
                        @change="changeWilayahDist()"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.region_id">{{
                        errorAdd.region_id[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Wilayah</label>
                    <Dropdown id="wilayah" dataKey="wilayah_id" v-model="forms.wilayah_id" :loading="loadingDropdownWilayah"
                        :options="dataDropdownWilayah" :class="{ 'p-invalid': errorAdd.wilayah_id }"
                        optionLabel="wilayah_name" optionValue="wilayah_id" placeholder="Pilih Wilayah"
                        :filter="true" :showClear="true" @filter="searchDropdownWilayah($event, 'add')"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.wilayah_id">{{
                        errorAdd.wilayah_id[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Distributor</label>
                    <Dropdown id="dist_code" dataKey="dist_code" v-model="forms.dist_code" :loading="loadingDropdownDistributor"
                        :options="dataDropdownDistributor" :class="{ 'p-invalid': errorAdd.dist_code }"
                        optionLabel="label" optionValue="dist_code" placeholder="Pilih Distributor"
                        :filter="true" :showClear="true" @filter="searchDropdownDistributor($event, 'add')"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.dist_code">{{
                        errorAdd.dist_code[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Channel Distribusi</label>
                <Dropdown id="cdistribusi_id" dataKey="cdistribusi_id" v-model="forms.cdistribusi_id" :loading="loadingDropdownChannelDistribusi"
                    :options="dataDropdownChannelDistribusi" :class="{ 'p-invalid': errorAdd.cdistribusi_id }"
                    optionLabel="cdistribusi_name" optionValue="cdistribusi_id" placeholder="Pilih Channel Distribusi"
                    :filter="true" :showClear="true" @filter="searchDropdownChannelDistribusi($event, 'add')"
                />
                <small class="p-invalid" style="color: red" v-if="errorAdd.cdistribusi_id">{{
                    errorAdd.cdistribusi_id[0]
            }}</small>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownKlasifikasi: false,
            loadingDropdownRegion: false,
            loadingDropdownWilayah: false,
            loadingDropdownDistributor: false,
            loadingDropdownChannelDistribusi: false,

            // dataDropdown
            dataDropdownKlasifikasi: null,
            dataDropdownRegion: null,
            dataDropdownWilayah: null,
            dataDropdownDistributor: null,
            dataDropdownChannelDistribusi: null,

            // addNew
            forms: {
                cust_code: null,
                cust_name: null,
                klasifikasi_id: null,
                region_id: null,
                wilayah_id: null,
                dist_code: null,
                cdistribusi_id: null,
            },
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownRegion('');
        this.searchDropdownKlasifikasi('');
        this.searchDropdownChannelDistribusi('');
    },
    computed:{
        ...mapGetters(['errorAdd']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        // DROPDOWN
        searchDropdownKlasifikasi(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dklsf.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownKlasifikasi = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-klasifikasi',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "add"){
                        this.dataDropdownKlasifikasi = res.data.data;
                        this.loadingDropdownKlasifikasi = false;
                    }else if(purpose == null){
                        this.dataDropdownKlasifikasi = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        searchDropdownChannelDistribusi(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dcnldist.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownChannelDistribusi = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-channel-distribusi',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "add"){
                        this.dataDropdownChannelDistribusi = res.data.data;
                        this.loadingDropdownChannelDistribusi = false;
                    }else if(purpose == null){
                        this.dataDropdownChannelDistribusi = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        searchDropdownRegion(event, purpose, valueEdit){
            setTimeout(() => {
                if(valueEdit){
                    this.$refs.drgn.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownRegion = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-region-v2',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "add"){
                        this.dataDropdownRegion = res.data.data;
                        this.loadingDropdownRegion = false;
                    }else if(purpose == null){
                        this.dataDropdownRegion = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        changeWilayahDist(){
            this.forms.wilayah_id= null;
            this.dataDropdownWilayah= null;
            this.searchDropdownWilayah('');

            this.forms.dist_code= null;
            this.dataDropdownDistributor= null;
            this.searchDropdownDistributor('');
        },
        searchDropdownWilayah(event, purpose, valueEdit){
            setTimeout(() => {
                if(this.forms.region_id){

                    if(valueEdit){
                        this.$refs.dwlyh.filterValue = valueEdit;
                    }

                    if(purpose == "add"){
                        this.loadingDropdownWilayah = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/master-wilayah',
                        params: {
                            "search" : valueEdit ? valueEdit : event.value,
                            "region_id" : this.forms.region_id
                        }
                    })
                    .then(res => {

                        if(purpose == "add"){
                            this.dataDropdownWilayah = res.data.data;
                            this.loadingDropdownWilayah = false;
                        }else if(purpose == null){
                            this.dataDropdownWilayah = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }
            }, 250);
        },
        searchDropdownDistributor(event, purpose, valueEdit) {
            setTimeout(() => {
                if(this.forms.region_id){

                    if (valueEdit) {
                        this.$refs.ddistributor.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownDistributor = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/master-distributor',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "region_id" : this.forms.region_id
                        }
                    })
                        .then(res => {

                            if (purpose == "add") {
                                this.dataDropdownDistributor = res.data.data;
                                this.loadingDropdownDistributor = false;
                            } else if (purpose == null) {
                                this.dataDropdownDistributor = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-customer/create',
                data: {
                    "cust_code": this.forms.cust_code,
                    "cust_name": this.forms.cust_name,
                    "klasifikasi_id": this.forms.klasifikasi_id,
                    "region_id": this.forms.region_id,
                    "wilayah_id": this.forms.wilayah_id,
                    "dist_code": this.forms.dist_code,
                    "cdistribusi_id": this.forms.cdistribusi_id,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.cust_code = null;
            this.forms.cust_name = null;
            this.forms.klasifikasi_id = null;
            this.forms.region_id = null;
            this.forms.wilayah_id = null;
            this.forms.dist_code = null;
            this.forms.cdistribusi_id = null;

            this.dataDropdownDistributor = null;
            this.dataDropdownWilayah = null;
        },
    }
}
</script>