<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
         <div class="field">
            <label>Kode Outlet</label>
            <InputText v-model="item.cust_code" required="true" autofocus :class="{ 'p-invalid': errorEdit.cust_code }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.cust_code" >{{ errorEdit.cust_code[0] }}</small >
        </div>
         <div class="field">
            <label>Nama Outlet</label>
            <InputText v-model="item.cust_name" required="true" autofocus :class="{ 'p-invalid': errorEdit.cust_name }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.cust_name" >{{ errorEdit.cust_name[0] }}</small >
        </div>
        <div class="field">
            <label>Klasifikasi Outlet</label>
            <Dropdown dataKey="klasifikasi_id" 
                    ref="dklsf" v-model="item.klasifikasi_id" 
                    :loading="loadingDropdownKlasifikasi" 
                    :options="dataDropdownKlasifikasi" 
                    :class="{ 'p-invalid': errorEdit.klasifikasi_id }" 
                    optionLabel="label" optionValue="klasifikasi_id" 
                    placeholder="Pilih Klasifikasi" :filter="true" 
                    :showClear="true" @filter="searchDropdownKlasifikasi($event,'edit')"
            />
            <small class="p-invalid" style="color: red" v-if="errorEdit.klasifikasi_id" >{{ errorEdit.klasifikasi_id[0] }}</small>
        </div>
        <div class="field">
            <label>Region</label>
            <Dropdown dataKey="region_id" 
                    ref="drgn" v-model="item.region_id" 
                    :loading="loadingDropdownRegion" 
                    :options="dataDropdownRegion" 
                    :class="{ 'p-invalid': errorEdit.region_id }" 
                    optionLabel="region_name" optionValue="region_id" 
                    placeholder="Pilih Region" :filter="true" 
                    :showClear="true" @filter="searchDropdownRegion($event,'edit')"
                    @change="changeWilayahDist()"
            />
            <small class="p-invalid" style="color: red" v-if="errorEdit.region_id" >{{ errorEdit.region_id[0] }}</small>
        </div>
        <div class="field">
            <label>Wilayah</label>
            <Dropdown dataKey="wilayah_id" 
                    ref="dwlyh" v-model="item.wilayah_id" 
                    :loading="loadingDropdownWilayah" 
                    :options="dataDropdownWilayah" 
                    :class="{ 'p-invalid': errorEdit.wilayah_id }" 
                    optionLabel="wilayah_name" optionValue="wilayah_id" 
                    placeholder="Pilih Wilayah" :filter="true" 
                    :showClear="true" @filter="searchDropdownWilayah($event,'edit')"
            />
            <small class="p-invalid" style="color: red" v-if="errorEdit.wilayah_id" >{{ errorEdit.wilayah_id[0] }}</small>
        </div>
        <div class="field">
            <label>Distributor</label>
            <Dropdown dataKey="dist_code" 
                    ref="ddistributor" v-model="item.dist_code" 
                    :loading="loadingDropdownDistributor" 
                    :options="dataDropdownDistributor" 
                    :class="{ 'p-invalid': errorEdit.dist_code }" 
                    optionLabel="label" optionValue="dist_code" 
                    placeholder="Pilih Distributor" :filter="true" 
                    :showClear="true" @filter="searchDropdownDistributor($event,'edit')"
            />
            <small class="p-invalid" style="color: red" v-if="errorEdit.dist_code" >{{ errorEdit.dist_code[0] }}</small>
        </div>
        <div class="field">
            <label>Channel Distribusi</label>
            <Dropdown dataKey="cdistribusi_id" 
                    ref="dcnldist" v-model="item.cdistribusi_id" 
                    :loading="loadingDropdownChannelDistribusi" 
                    :options="dataDropdownChannelDistribusi" 
                    :class="{ 'p-invalid': errorEdit.cdistribusi_id }" 
                    optionLabel="cdistribusi_name" optionValue="cdistribusi_id" 
                    placeholder="Pilih Channel Distribusi" :filter="true" 
                    :showClear="true" @filter="searchDropdownChannelDistribusi($event,'edit')"
            />
            <small class="p-invalid" style="color: red" v-if="errorEdit.klasifikasi_id" >{{ errorEdit.klasifikasi_id[0] }}</small>
        </div>
        <template #footer>
            <Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownKlasifikasi: false,
            loadingDropdownRegion: false,
            loadingDropdownWilayah: false,
            loadingDropdownDistributor: false,
            loadingDropdownChannelDistribusi: false,

            // dataDropdown
            dataDropdownKlasifikasi: null,
            dataDropdownRegion: null,
            dataDropdownWilayah: null,
            dataDropdownDistributor: null,
            dataDropdownChannelDistribusi: null,

            // edit
            editItemDialog: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if(this.editItemDialog == true){
                        this.searchDropdownKlasifikasi('', 'edit', this.item.klasifikasi_id + '-' + this.item.klasifikasi_name);
                        this.searchDropdownRegion('', 'edit', this.item.region_name);
                        this.searchDropdownWilayah('', 'edit', this.item.wilayah_name);
                        this.searchDropdownDistributor('', 'edit', this.item.dist_code + '-' +this.item.dist_name);
                        this.searchDropdownChannelDistribusi('', 'edit', this.item.cdistribusi_name);
                    }
                    this.$store.commit('setErrorEdit', {});
                }, 500);
            },
        }
    },
    computed:{
        ...mapGetters(['errorEdit']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        // DROPDOWN
        searchDropdownChannelDistribusi(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dcnldist.filterValue = valueEdit;
                }

                if(purpose == "edit"){
                    this.loadingDropdownChannelDistribusi = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-channel-distribusi',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "edit"){
                        this.dataDropdownChannelDistribusi = res.data.data;
                        this.loadingDropdownChannelDistribusi = false;
                    }else if(purpose == null){
                        this.dataDropdownChannelDistribusi = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        searchDropdownKlasifikasi(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dklsf.filterValue = valueEdit;
                }

                if(purpose == "edit"){
                    this.loadingDropdownKlasifikasi = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-klasifikasi',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "edit"){
                        this.dataDropdownKlasifikasi = res.data.data;
                        this.loadingDropdownKlasifikasi = false;
                    }else if(purpose == null){
                        this.dataDropdownKlasifikasi = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        searchDropdownRegion(event, purpose, valueEdit){
            setTimeout(() => {
                if(valueEdit){
                    this.$refs.drgn.filterValue = valueEdit;
                }

                if(purpose == "edit"){
                    this.loadingDropdownRegion = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-region-v2',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "edit"){
                        this.dataDropdownRegion = res.data.data;
                        this.loadingDropdownRegion = false;
                    }else if(purpose == null){
                        this.dataDropdownRegion = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        changeWilayahDist(){
            // eslint-disable-next-line vue/no-mutating-props
            this.item.wilayah_id= null;
            this.dataDropdownWilayah= null;
            this.searchDropdownWilayah('');


            // eslint-disable-next-line vue/no-mutating-props
            this.item.dist_code= null;
            this.dataDropdownDistributor= null;
            this.searchDropdownDistributor('');
        },
        searchDropdownWilayah(event, purpose, valueEdit){
            setTimeout(() => {
                if(this.item.region_id){

                    if(valueEdit){
                        this.$refs.dwlyh.filterValue = valueEdit;
                    }

                    if(purpose == "edit"){
                        this.loadingDropdownWilayah = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/master-wilayah',
                        params: {
                            "search" : valueEdit ? valueEdit : event.value,
                            "region_id" : this.item.region_id
                        }
                    })
                    .then(res => {

                        if(purpose == "edit"){
                            this.dataDropdownWilayah = res.data.data;
                            this.loadingDropdownWilayah = false;
                        }else if(purpose == null){
                            this.dataDropdownWilayah = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }
            }, 250);
        },
        searchDropdownDistributor(event, purpose, valueEdit) {
            setTimeout(() => {
                if(this.item.region_id){

                    if (valueEdit) {
                        this.$refs.ddistributor.filterValue = valueEdit;
                    }

                    if (purpose == "edit") {
                        this.loadingDropdownDistributor = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/master-distributor',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "region_id" : this.item.region_id
                        }
                    })
                        .then(res => {

                            if (purpose == "edit") {
                                this.dataDropdownDistributor = res.data.data;
                                this.loadingDropdownDistributor = false;
                            } else if (purpose == null) {
                                this.dataDropdownDistributor = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        // EDIT
		hideDialog() {
			this.editItemDialog = false;
		},
		updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-customer/update',
                data: {
                    "cust_id": this.item.cust_id,
                    "cust_code": this.item.cust_code,
                    "cust_name": this.item.cust_name,
                    "klasifikasi_id": this.item.klasifikasi_id,
                    "region_id": this.item.region_id,
                    "wilayah_id": this.item.wilayah_id,
                    "dist_code": this.item.dist_code,
                    "cdistribusi_id": this.item.cdistribusi_id,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Diupdate', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.editItemDialog = false;
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diupdate', life: 3000,});
            });
		},
    }
}
</script>